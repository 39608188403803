import React from "react";
import { Typography, Link } from "@novaescola/eva-react-v2";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Seo from "../components/general/Seo"

const NotFoundPage: React.FC = () => {
  return (
    <Layout heroClass="no-hero" headerClass="padding-header">
    <Seo
      title="Página não encontrada" 
      description="Ops! Não encontramos a página que você está procurando"
    />
    <section id="not-found" className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12 d-flex jc-center fd-column">

          <Typography variant="title" size={3} className="eva-margin-bottom-4">
            Ops! Não encontramos a página que você está procurando
          </Typography>
   
          <Typography variant="body" size={2} className="eva-margin-bottom-1">
            Aqui estão alguns links úteis:
          </Typography>

          <Link href="https://novaescola.org.br/" component="a" variant="azul">
            Página inicial Nova Escola
          </Link>

          <Link href="https://novaescola.zendesk.com/hc/pt-br" component="a" variant="azul">
            Fale conosco
          </Link>
      
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 d-flex jc-center fd-column eva-margin-top-4 eva-margin-bottom-4">
          <StaticImage
            src="https://novaescola.org.br/imagens/erro404.png"
            alt=""
          />
        </div>
      </div>
    </section>
  </Layout>
  );
};

export default NotFoundPage;
